import StatusAdminPage from "pages/admin/status";
import Platform from "pages/start/platform";
import { HashRouter, Route, Switch } from "react-router-dom";
import { CoreLayout, DefaultLayout } from "./layouts";
import AccountPage from "./pages/account";
import HomePage from "./pages/home";
import InvitePage from "./pages/invite";
import Leaderboard from "./pages/leaderboard";
import LevelPage from "./pages/levels";
import ScorePage from "./pages/score";
import StartPage from "./pages/start";
import TaskPage from "./pages/tasks";
import MenuAdminPage from "pages/admin/menu";
import LeaderboardAdmin from "pages/leaderboardAdmin";
import AdminPerUser from "pages/AdminPerUser";
import CheckinPage from "pages/start/checkin";
import TransactionPage from "pages/tasks/transactions";
import CampaignPage from "pages/admin/campaign";
// import BoostPage from "pages/boost";
import Endgamepage from "pages/start/endgame";

// Component to render a route with a layout
const RouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

function App() {
  return (
    <HashRouter>
      <Switch>
        <RouteWithLayout
          path="/"
          layout={DefaultLayout}
          component={Endgamepage}
        />
        {/* <RouteWithLayout
          exact
          path="/youtubevideo/:taskid"
          layout={DefaultLayout}
          component={YoutubePage}
        />
        <RouteWithLayout
          exact
          path="/redirect/:userId"
          layout={DefaultLayout}
          component={RedirectComponent}
        />
        <RouteWithLayout
          exact
          path="/airdrop"
          layout={CoreLayout}
          component={AirdropPage}
        /> */}
      </Switch>
    </HashRouter>
  );
}

export default App;
