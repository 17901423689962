import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { APIClient } from "api";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { formatNumDynDecimal } from "utils";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";

function AdminPerUser() {
  const { id: refUserId } = useParams();
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [userRef, setUserRef] = useState([]);
  const [userRefSumStats, setUserRefSumStats] = useState(null);
  const [sortField, setSortField] = useState("last_auto_play");
  const [limit, setLimit] = useState(10);

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    (async () => {
      const resp = await APIClient.getUserAdmin(" ", refUserId);
      console.log("resp", resp);
      if (resp.success === true) {
        setUser(resp.data);
      }
      const res = await APIClient.fetchUserRefAdmin({
        refUserId,
        limit,
        sortField,
      });
      if (res.success === true) {
        const {
          data,
          sumStats,
          pointRange,
          refCountRange,
          refPointRange,
          ageRange,
          levelRange,
        } = res;
        setUserRef(data);
        setUserRefSumStats(sumStats?.[0]);
        setChartData({
          pointRange,
          refCountRange,
          refPointRange,
          ageRange,
          levelRange,
        });
      }
    })();
  }, [limit, refUserId, sortField]);
  console.log({ chartData });
  return (
    <Box bg="white" p="4">
      <Button
        size="xs"
        mb="8px"
        role="button"
        onClick={() => history.push(`/admin/leaderboard`)}
      >
        Back
      </Button>
      <Heading as="h4" size="md">
        Ref By User: {user?.username} - ID: {user?.id} - Point:{" "}
        {formatNumDynDecimal(parseInt(user?.point))} - Ref Count:{" "}
        {formatNumDynDecimal(user?.refer_count)} - Ref Point:{" "}
        {formatNumDynDecimal(parseInt(user?.refer_point))}
      </Heading>
      <Wrap py="24px">
        {chartData &&
          Object.entries(chartData)?.map(([label, data]) => (
            <WrapItem w="440px">
              <Card variant={"filled"} p="8px" w="full" fontSize={"12px"}>
                <Heading mb="12px" as="h5" fontSize="18px" textAlign="center">
                  {label}
                </Heading>

                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <PieChart data={data} />
                  <TableContainer>
                    <Table size="sm" variant="striped" colorScheme="teal">
                      <Thead>
                        <Tr>
                          <Th>Label</Th>
                          <Th isNumeric>Value</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data?.map(({ label, value }) => (
                          <Tr>
                            <Td>{label}</Td>
                            <Td isNumeric>{value}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              </Card>
            </WrapItem>
          ))}
      </Wrap>
      <Flex my="4">
        <Flex m="2">
          <Text>Sort by</Text>
          {[
            "last_auto_play",
            "refer_count",
            "refer_point",
            "point",
            "age",
            "level",
          ]?.map((item) => (
            <Button mx="4px" size="xs" onClick={() => setSortField(item)}>
              {item}
            </Button>
          ))}
        </Flex>
        <Flex m="2">
          <Text>Item load by</Text>
          {[5, 10, 50, 100, 500]?.map((item) => (
            <Button mx="4px" size="xs" onClick={() => setLimit(item)}>
              {item}
            </Button>
          ))}
        </Flex>
      </Flex>

      <Heading as="h4" size="md">
        All Ref: Total Point:{" "}
        {formatNumDynDecimal(parseInt(userRefSumStats?.totalPoint) || 0)} - F2
        Total Ref Count:{" "}
        {formatNumDynDecimal(userRefSumStats?.totalReferCount || 0)}
      </Heading>

      <Table size="sm" variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>No</Th>
            <Th>ID</Th>
            <Th>Username</Th>
            <Th>Point</Th>
            <Th>last_sync_point</Th>
            <Th>refer</Th>
            <Th>refer_count</Th>
            <Th>refer_point</Th>
            <Th>age</Th>
            <Th>last_auto_play</Th>
            <Th>level</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userRef?.map((user, idx) => (
            <Tr key={user.id}>
              <Td>#{idx + 1}</Td>
              <Td>
                <Text
                  role="button"
                  fontWeight={600}
                  onClick={() => history.push(`/admin/${user.id}`)}
                >
                  {user.id}
                </Text>
              </Td>
              <Td>{user.first_name}</Td>
              <Td>{formatNumDynDecimal(parseInt(user.point))}</Td>
              <Td>{formatNumDynDecimal(parseInt(user.last_sync_point))}</Td>
              <Td>{user.refer}</Td>
              <Td>{user.refer_count}</Td>
              <Td>{formatNumDynDecimal(parseInt(user.refer_point))}</Td>
              <Td>{user.age}</Td>
              <Td>{user.last_auto_play}</Td>
              <Td>{user.level}</Td>
            </Tr>
          ))}
        </Tbody>{" "}
      </Table>
    </Box>
  );
}

export default AdminPerUser;

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = ({ data, title }) => {
  const chartData = {
    labels: data?.map((item) => item.label),
    datasets: [
      {
        data: data?.map((item) => item.value),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "left",
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  return (
    <Box w="240px">
      <Pie data={chartData} options={options} />
    </Box>
  );
};
